import { useEffect, useState } from "react";
import {
  DictionaryComponent,
  Theme,
  AppConfig,
  Dictionary,
} from "njamed-dictionary-maker";

const jangunyTheme: Theme = {
  palette: {
    primary: "hsl(120, 5%, 25%)",
    secondary: "hsl(7, 66%, 45%)",
    tertiary: "hsl(44, 52%, 94%)",
    accent: "hsl(53, 50%, 94%)",
    accentText: "hsl(36, 19%, 40%)",
    paper: "hsl(30, 14%, 99%)",
    green: "hsl(163, 36%, 37%)",
    brown: "hsl(37, 16%, 42%)",
    text: "#222",
    lightText: "#777",
    background: "#212121",
    border: "#212121",
    error: "#f44336",
    success: "#4caf50",
    warning: "#ff9800",
    info: "#2196f3",
  },
  typography: {
    text1: `'Muli', serif`,
    text2: `'Meloso', serif`,
    displayFontFamily: "'Meloso', serif",
  },
};

const jangunyConfig: AppConfig = {
  title: "Gijingarliya Wengga",
  subtitle: "Burarra and Gun-nartpa dictionary",
  audioCloudSrc: "https://res.cloudinary.com/dykmwkpgw/video/upload/janguny/",
  imgCloudSrc: "https://res.cloudinary.com/dykmwkpgw/image/upload/janguny/",
  appIcon: {
    src: "https://res.cloudinary.com/dykmwkpgw/image/upload/v1711414367/m2896qvzbcioesyxfvow.svg",
    alt: "Dictionary icon",
    paddingVertical: 4,
  },
  sourceLanguage: {
    name: "English",
    shortName: "En",
  },
  targetLanguage: {
    name: "Burarra",
    shortName: "Bur",
  },
  captchaPageText: {
    text: "# Welcome\n\nWelcome to Gijingarliya Wengga, a dictionary of Burarra and Gun-nartpa. Gijingarliya means 'from the tongue' and also 'language'. Wengga means 'speech'. We pay our respect to all the Burarra and Gun-nartpa elders, educators and literacy workers past, present and emerging.\n\n## Indigenous Cultural and Intellectual Property\n\nThe Burarra and Gun-nartpa (Gijingarliya) Language is the Indigenous Cultural Intellectual Property of Gu-galiya Yerrcha (local people who know this language), and the right to use it is subject to traditional law and custom. This website is intended for use by individuals learning Burarra and Gun-nartpa for the purpose of communication. Any other use of the language or information on this site, including teaching, research, or any commercial use requires the consent of the community. Please contact the Bininj Kunwok Regional Language Centre Ltd (info@bininjkunwok.org.au) or the Maningrida Culture Office (Culture.Office@bawinanga.com) for any enquiries.",
  },
  icons: {
    Dictionary: "book",
    Categories: "bookshelf",
    Info: "info",
    About: "info",
    Phrasebook: "speechBubble",
  },
};

function App() {
  const [dictionary, setDictionary] = useState<Dictionary | null>(null);

  useEffect(() => {
    const dataPath = `${process.env.PUBLIC_URL}/data/normalized-entries.json`;
    fetch(dataPath)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Got the data!");
        setDictionary(data);
      });
  }, []);

  return (
    <DictionaryComponent
      data={dictionary}
      theme={jangunyTheme}
      appConfig={jangunyConfig}
    />
  );
}

export default App;
